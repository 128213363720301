import {messageActions} from 'redux/actions/messageActions';

const checkOnlineMiddleware = store => next => action => {
    // On check seulement si l'action nécessite une connexion
    if(action && action.online === true){
        if (window.navigator.onLine !== true) {
            const noConnexion = {
                messageText: "Vous êtes hors connexion. Impossible de récupérer vos données à jour",
                messageType:  "error"
            };
            store.dispatch(messageActions.showMessage(noConnexion));
            return;
        }
    }
    return next(action);
}

export default checkOnlineMiddleware;