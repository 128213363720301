import React from 'react';
import {withRouter} from 'react-router-dom';

import DisplayError from 'components/graphics/DisplayError';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // On met le state à jour pour afficher notre page d'erreur
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      // TODO logguer l'erreur dans un système type avec plugin type Loglevel
    }

    gotoHome = () => {
        this.setState({hasError: false});
        this.props.history.push("list-coupons");
        window.location.reload();
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <DisplayError onClick={this.gotoHome} />
        )
      }
  
      return this.props.children; 
    }
  }

  export default withRouter(ErrorBoundary);