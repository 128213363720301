
const messageFormater = (objectMessage) =>{
    let sParsedMessage = "";
    /// On transforme le message s'il est sous forme d'objet
    if(typeof objectMessage === 'object'){
        for (const value of Object.entries(objectMessage)) {
            sParsedMessage += value+"\n";
        }
    }else{
    sParsedMessage = objectMessage;
    }
    return sParsedMessage;
}

export default messageFormater;