import React from 'react'


const SocialNetwork = () => (
    <div className="paypsRs">
        {window['webAppContent'].MENU.FIND_PAYPS_BTN}
        <div className="rsRow">
        <div className="menuFacebook" onClick={() => window.open('https://www.facebook.com/Paypsretail')}></div>
        <div className="menuInstagram" onClick={() => window.open('https://www.instagram.com/payps_webapp/')}></div>
        <div className="menuEmail" onClick={() => window.location.href="mailto:contact@payps.fr"}></div>
        </div>
        <div className="version">
        {process.env.REACT_APP_APP_VERSION}
        </div>
    </div>
  )

export default SocialNetwork;