import React from 'react'


const LoadingItem = () => (
    <div className="loadingItem">
        <div>
            </div>
                <div>
                </div>
            <div>
        </div>
    </div>
  )

export default LoadingItem;