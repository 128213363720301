export const userConstants = {

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    SOCIAL_LOGIN_REQUEST: 'USERS_SOCIAL_LOGIN_REQUEST',
    SOCIAL_LOGIN_SUCCESS: 'USERS_SOCIAL_LOGIN_SUCCESS',
    SOCIAL_LOGIN_FAILURE: 'USERS_SOCIAL_LOGIN_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    SOCIAL_REGISTER_REQUEST: 'USERS_SOCIAL_REGISTER_REQUEST',
    SOCIAL_REGISTER_SUCCESS: 'USERS_SOCIAL_REGISTER_SUCCESS',
    SOCIAL_REGISTER_FAILURE: 'USERS_SOCIAL_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'USER_UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'USER_UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'USER_UPDATE_PASSWORD_FAILURE',

    ASK_RESET_PASSWORD_REQUEST: 'ASK_USER_RESET_PASSWORD_REQUEST',
    ASK_RESET_PASSWORD_SUCCESS: 'ASK_USER_RESET_PASSWORD_SUCCESS',
    ASK_RESET_PASSWORD_FAILURE: 'ASK_USER_RESET_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',

    DISPLAY_PASSWORD_FORM: 'DISPLAY_PASSWORD_FORM',
    PASSWORD_UPDATED: 'PASSWORD_UPDATED',

    REGISTRATION_NEEDED: 'PREGISTRATION_NEEDED',
    
    USER_LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',  
    USER_LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',  
    USER_LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',  

    USER_UPDATE_GEOLOC_REQUEST: 'USER_UPDATE_GEOLOC_REQUEST',
    USER_UPDATE_GEOLOC_SUCCESS: 'USER_UPDATE_GEOLOC_SUCCESS',
    USER_UPDATE_GEOLOC_FAILURE: 'USER_UPDATE_GEOLOC_FAILURE',

    USER_UNKNOWN_GEOLOC_ACCEPT: 'USER_UNKNOWN_GEOLOC_ACCEPT',
    USER_UNKNOWN_COORD_ACCEPT: 'USER_UNKNOWN_COORD_ACCEPT',

    USER_ACCEPT_FULL_PAYPS_REQUEST: 'USER_ACCEPT_FULL_PAYPS_REQUEST',
    USER_ACCEPT_FULL_PAYPS_SUCCESS: 'USER_ACCEPT_FULL_PAYPS_SUCCESS',
    USER_ACCEPT_FULL_PAYPS_FAILURE: 'USER_ACCEPT_FULL_PAYPS_FAILURE',

    USER_ACCEPT_CLUB_CGU_REQUEST: 'USER_ACCEPT_CLUB_CGU_REQUEST',
    USER_ACCEPT_CLUB_CGU_SUCCESS: 'USER_ACCEPT_CLUB_CGU_SUCCESS',
    USER_ACCEPT_CLUB_CGU_FAILURE: 'USER_ACCEPT_CLUB_CGU_FAILURE',
};
