import { loadStorage, cleanStorage } from 'components/helpers/storageManager';
import { userConstants } from 'redux/constants/userConstants';
import jwtDecoder from 'components/helpers/jwtDecoder';
import userConnexion from 'components/connectors/userConnexion';
import { userActions } from 'redux/actions/userActions';
import history from "components/helpers/history.js";

let postponedRequests = [];
// Middleware qui s'occupe de rafraishir le token si nécessaire avant d'exécuter une requête à l'API

  const requestMiddleware = store => next => action => {
      //console.log("requestMiddleware ", action);
    if(action === undefined){
        //console.log("ici on a une action undefined");
        return;
        //return next(action);
    }
    switch(action.type){
        case userConstants.LOGIN_REQUEST:
        case userConstants.LOGIN_FAILURE:
        case userConstants.REGISTER_REQUEST:
        case userConstants.REGISTER_FAILURE:
            return next(action);
        
        default:
            // Action qui nécessite de checker le token
        break;

    }
 
    // Si le localStorage est vide cela signifie qu'il n'y a rien à rafraichir
    if(loadStorage() === undefined || loadStorage().accessToken === undefined){
        return next(action);
    }

    // Si l'accessToken est undefined de même on renvoie sur la prochaine action également
    if(loadStorage().accessToken.access_token === undefined){
        cleanStorage();
        return next(action);
    }
    

    const {user, accessToken, tokenRefreshing} = loadStorage();
    const expDelay = Date.now()+10000;
    // Si le refreshToken est déjà en cours on attend la mise à jour du token pour continuer l'action
    // On stocke l'action dans un tableau à exécuter plus tard
    // TODO a voir si on peut faire un middleware plus propre qui prend directement en charge le process
   // console.log("on va checker si le token est en cours de refresh ", tokenRefreshing);
    if((tokenRefreshing && (parseInt(tokenRefreshing) < expDelay)) && action !== undefined){
        postponedRequests.push(action);
        //console.log("refresh en cours on postpone ", action);
    }else{
        const payload = jwtDecoder(accessToken.access_token);
        // Calcule de la limite de durée de vie du token avec une marge de 5 sec
        const refreshThreshold = expDelay / 1000;
        if(accessToken.refresh_token && refreshThreshold > payload.exp){
            //console.log("on refresh le token --");
            userConnexion.refresh(user.uuid, accessToken.refresh_token)
                .then((accessToken) => {
                    // Le token a été mis à jour, on execute la requete suivante
                    const nextAction = postponedRequests.pop();
                    //console.log("token mis à jour on va lancer l'action suivant ", nextAction);
                    store.dispatch(nextAction);
                    return next(action);
                }, (error) => {
                    // We clean the array of actions and redirect to the login page
                    postponedRequests = [];
                    store.dispatch(userActions.logout());
                    history.push("/login");
                    if(error.response){
                        return Promise.reject(error.response.data.message);
                    }else{
                        return Promise.reject(error);
                    }
                });
        }else{
            //console.log("On check si on a des requetes en attente à relancer ", postponedRequests.length);
            // Dans le cas ou il reste encore une requete en attente on relance le dispatch
            if(postponedRequests.length > 0){
                const nextAction = postponedRequests.pop();
                store.dispatch(nextAction);
            }
            return next(action);
        }
    }
  }


  export default requestMiddleware;