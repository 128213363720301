import {messageActions} from 'redux/actions/messageActions';

const checkErrorMiddleware = store => next => action => {
    // On check seulement si l'action nécessite une connexion
    if(action && action.error && !action.socialRedirect){
            const errorAction = {
                messageText: action.error,
                messageType:  "error"
            };
            store.dispatch(messageActions.showMessage(errorAction));
        }
    return next(action);
}

export default checkErrorMiddleware;