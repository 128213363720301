import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import SidebarMenu from 'components/modules/header/SidebarMenu';
import CartIcon from 'components/modules/header/CartIcon';

class Header extends Component{

    goBack = (event) => {
        if(this.props.location.search !== ""){
            this.props.history.push("/list-coupons");
        }else{
            this.props.history.goBack();
        }
    }

    gotoCoupons = (event) =>{
        this.props.history.push("/list-coupons");
    }

    render(){
        const {showRealHeader, showReturnButton} = this.props;
        const defaultClass = window['webAppConfig'].webAppEnv;

        return(
            <div className="headerContainer">
                {showRealHeader &&
                    <div className={`header${defaultClass}`}>
                        {
                            showReturnButton && 
                            <div className="returnButton" onClick={this.goBack}>
                                <img src={process.env.REACT_APP_IMAGES_HOST+window['webAppConfig'].images.returnBtn} alt="retour" />
                            </div>
                        }
                        <SidebarMenu />
                        <div className="logoContainer" onClick={this.gotoCoupons}>
                            <img src={process.env.REACT_APP_IMAGES_HOST+window['webAppConfig'].images.logoMenu} className="miniLogo" alt="Payps"/>
                        </div>
                        <CartIcon />
                    </div>
                }
            </div>
        )
    }
}

Header.propTypes= {
    showRealHeader: PropTypes.bool,
    showReturnButton: PropTypes.bool
};

export default withRouter(Header);