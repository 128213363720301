import { messageConstants } from 'redux/constants/messageConstants';

const initialState = {};

export function messaging(state = initialState, action) {
    switch (action.type) {

        case messageConstants.MESSAGE_SHOW:
            return {
                ...state,
                messageText: action.messageData.messageText,
                messageType: action.messageData.messageType,
                showArrow: action.messageData.showArrow ? action.messageData.showArrow : true,
                messageShow: true
            };

        case messageConstants.MESSAGE_HIDE:
            return {
                ...state,
                messageText: "",
                messageType: "",
                messageShow: false,
                showArrow: true,
            };

        default:
            return state
    }
}