import axios from 'axios';
import { saveStorage, loadStorage, cleanStorage } from 'components/helpers/storageManager';

class userConnexion {

    /*
    *Classic Login
    @Parameters 
    email -> string
    password -> string
    **/
   static async login(userData){
        try{
            const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/user/login', userData);
            if(response.data.valide === 1){
              const paypsStorage = {user: response.data.userData, accessToken:response.data.auth_token};
              saveStorage(paypsStorage);
              return Promise.resolve(response.data.userData);
            }else{
              return Promise.reject(response.data.message);
            }
          }
          catch(error){
            return Promise.reject("Désolé, impossible d'identifier ce compte");
          }
    }

    /*
    *Social Login
    @Parameters 
    provider -> string /// Social network used to connect
    token -> string  /// Access_token provided by social network
    **/
  static async sociallLogin(userData){
      try{
          const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/social/loginByToken', userData);
          if(response.data.valide === 1){
            const paypsStorage = {user: response.data.userData, accessToken:response.data.auth_token};
            saveStorage(paypsStorage);
            return Promise.resolve(response.data.userData);
          }else{
            return Promise.reject(response.data);
          }
        }
        catch(error){
          return Promise.reject(error.response.data);
        }
  }

  /*
    *Register new user with social network
    @Parameters 
    gender -> Integer
    firstName -> string
    lastName -> string
    email -> string
    password -> string
    **/
  static async socialRegister(userData){
    try{
        const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/social/registerByToken', userData);
        if(response.data.valide === 1){
          const paypsStorage = {user: response.data.userData, accessToken:response.data.auth_token};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data.userData);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible d'enregistrer ce compte "+ JSON.stringify(error.response.data.message));
      }
}

    /*
    *Register new user
    @Parameters 
    gender -> Integer
    firstName -> string
    lastName -> string
    email -> string
    password -> string
    **/
    static async register(userData){
      try{
          const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/user/create', userData);
          if(response.data.valide === 1){
            const paypsStorage = {user: response.data.userData, accessToken:response.data.auth_token};
            saveStorage(paypsStorage);
            return Promise.resolve(response.data.userData);
          }else{
            return Promise.reject(response.data.message);
          }
        }
        catch(error){
          return Promise.reject("Désolé, impossible d'enregistrer ce compte ");
        }
  }

  /*
    *Update registered user
    @Parameters 
    gender -> Integer
    firstName -> string
    lastName -> string
    email -> string
    birthdate -> date
    address -> string
    postal -> string
    country -> string
    **/
  static async update(userData){
    const config = {
      headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
    };
    const uuid = loadStorage().user.uuid;
    try{
        const response = await axios.put(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/update', userData, config);
        if(response.data.valide === 1){
          const paypsStorage = {user: response.data.userData};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data.userData);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de mettre à jour ce compte");
      }
  }

  /*
    *Update registered user's password
    @Parameters 
    oldPassword -> string
    password -> string
    **/
  static async updatePassword(password){
    const config = {
      headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
    };
    const uuid = loadStorage().user.uuid;
    try{
        const response = await axios.put(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/updatePassword', password, config);
        if(response.data.valide === 1){
          const paypsStorage = {user: response.data.userData};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data.userData);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de mettre à jour votre mot de passe");
      }
  }

  /*
    *Ask to received rest password email
    @Parameters 
    email -> string
    **/
   static async askResetPassword(email){
    try{
        const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/password/resetPassword', email);
        if(response.data.valide === 1){
          return Promise.resolve(true);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de vous envoyer l'email de renouvellement");
      }
  }

  /*
    *Reset registered user's password
    @Parameters 
    token -> string
    password -> string
    **/
   static async resetPassword(passwordData){
    try{
        const response = await axios.put(process.env.REACT_APP_API_PAYPS+'/password/reset', passwordData);
        if(response.data.valide === 1){
          return Promise.resolve(true);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de mettre à jour votre mot de passe");
      }
  }

  /*
    *Refresh User's access_token
    @Parameters 
    refreshToken -> string
    **/
  static async refresh(uuid, refreshToken){
    try{
        const tokenRefreshing = {tokenRefreshing: Date.now()};
        saveStorage(tokenRefreshing);
        const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/refresh', {refreshToken : refreshToken});
        if(response.data.valide === 1){
          const actualStorage = loadStorage();
          if(actualStorage === undefined){
            return Promise.reject('Erreur impossible de récupérer le Storage');
          }
          const paypsStorage = {user : actualStorage.user, accessToken:response.data.auth_token, tokenRefreshing: null};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data);
        }else{
          cleanStorage();
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        cleanStorage();
        return Promise.reject("Désolé, impossible de renouveller vore token");
      }
  }

   /*
    *Change User choice for Geoloc
    @Parameters 
    geoloc -> boolean
    **/
   static async updateUserGeoloc(geoloc){
    const config = {
      headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
    };
    const uuid = loadStorage().user.uuid;
    try{
        const response = await axios.put(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/updateGeoloc', geoloc, config);
        if(response.data.valide === 1){
          const paypsStorage = {user: response.data.userData};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data.userData);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de mettre à jour votre choix de géolocalisation");
      }
  }

  /*
  * Accept to be full Payps (i.e. see all the campaigns available)
  * 
  * @param boolean isFull
  **/
    static async acceptFullPayps(isFull){
      const config = {
        headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
      };
      const uuid = loadStorage().user.uuid;
      try{
          const response = await axios.put(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/updateFullPayps', isFull, config);
          if(response.data.valide === 1){
            const paypsStorage = {user: response.data.userData};
            saveStorage(paypsStorage);
            return Promise.resolve(response.data.userData);
          }else{
            return Promise.reject(response.data.message);
          }
        }
        catch(error){
          return Promise.reject("Désolé, impossible de mettre à jour votre compte Payps");
        }
    }


  /**
   * Just updates the localStorage for unknown user geoloc
   */
  static acceptUnknownGeoloc(){
    try{
      let localUser = (loadStorage() && loadStorage().user) ? loadStorage().user : {user:{}};
      localUser.user.geoloc = 1;
      saveStorage(localUser);
      return Promise.resolve(localUser);
    }
    catch(error){
      return Promise.reject("Impossible d'enregistrer l'acceptation de la geoloc ");
    }
  }

    /**
   * Just updates the localStorage for unknown user coordinates
   */
    static acceptUnknownCoord(coordinates){
      try{
        let user = (loadStorage() && loadStorage().user) ? loadStorage().user : {};
        user.coordinates = coordinates;
        saveStorage(user);
        return Promise.resolve(user);
      }
      catch(error){
        return Promise.reject("Impossible d'enregistrer les coordonnées ");
      }
    }

  /**
   * Update Club By Payps CGU
   */
   static async acceptClubCgu(hasAccepted){
    const config = {
      headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
    };
    const uuid = loadStorage().user.uuid;
    try{
        const response = await axios.put(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/updateFidelity', hasAccepted, config);
        if(response.data.valide === 1){
          const paypsStorage = {user: response.data.userData};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data.userData);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de mettre à jour votre compte Payps");
      }
  }


  static logout(){
    try{
      cleanStorage();
      return Promise.resolve("Utilisateur déconnecté");
    }
    catch(error){
      return Promise.reject("Impossible de déconnecter l'utilisateur");
    }
  }


  static isLoggedIn(){
    const userData = loadStorage();
    if(userData && userData.user && userData.user.uuid){
      return true;
    }else{
      return false;
    }
  }

  static hasAcceptedFidelity(){
    const userData = loadStorage();
    if(userData && userData.user && userData.user.has_accepted_fidelity){
      return true;
    }else{
      return false;
    }
  }

}

export default userConnexion;