import { combineReducers } from 'redux';

import { authentication } from 'redux/reducers/authenticationReducer';
import { couponing } from 'redux/reducers/couponingReducer';
import { messaging } from 'redux/reducers/messagingReducer';
import { giftCards } from 'redux/reducers/giftCardsReducer';

export const rootReducer = combineReducers({
  authentication,
  couponing,
  messaging,
  giftCards
});
