import { createTheme } from '@mui/material/styles';

const PaypsMuiTheme = createTheme({
    palette: {
      primary: {
        main: window['webAppConfig'].colors.secondary,
      },
      secondary: {
        main: window['webAppConfig'].colors.background,
      },
    },
    components:{
      MuiInputBase:{
        styleOverrides:{
          input: {
            //color: window['webAppConfig'].colors.primary,
            backgroundColor: 'transparent',
            color: '#474747',
        }
        },
      }
    },
  });

  export default PaypsMuiTheme;