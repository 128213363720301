import { userConstants } from 'redux/constants/userConstants';
import  userConnexion  from 'components/connectors/userConnexion';



const login = (userData) => {
    return dispatch => {
        dispatch(request({ userData }));

        userConnexion.login(userData)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { online: true, type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

const socialLogin = (userData, socialResponse) => {
    return dispatch => {
        dispatch(request({ userData }));

        userConnexion.sociallLogin(userData)
            .then(
                user => { 
                    dispatch(success(user));
                },
                response => {
                    const socialRedirect = (response.socialRedirect === true) ? true : false;
                    dispatch(failure(response.message.toString(), userData.provider, socialResponse, socialRedirect));
                }
            );
    };

    function request(user) { return { online: true, type: userConstants.SOCIAL_LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.SOCIAL_LOGIN_SUCCESS, user } }
    function failure(error, provider, socialResponse, socialRedirect) { return { type: userConstants.SOCIAL_LOGIN_FAILURE, error, provider: provider, socialResponse: socialResponse, socialRedirect: socialRedirect } }
}

const register = (userData)  =>{
    return dispatch => {
        dispatch(request( {userData }));

        userConnexion.register(userData)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { online: true, type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

const socialRegister = (userData)  =>{
    return dispatch => {
        dispatch(request( {userData }));

        userConnexion.socialRegister(userData)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { online: true, type: userConstants.SOCIAL_REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.SOCIAL_REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SOCIAL_REGISTER_FAILURE, error } }
}

const update = (userData)  =>{
    return dispatch => {
        dispatch(request( {userData }));

        userConnexion.update(userData)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { online: true, type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

const updatePassword = (password)  =>{
    return dispatch => {
        dispatch(request( {password }));

        userConnexion.updatePassword(password)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { online: true, type: userConstants.UPDATE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error } }
}

const resetPassword = (passwordData)  =>{
   return function(dispatch) {
        dispatch(request(passwordData));
        
        return userConnexion.resetPassword(passwordData)
        .then(
            valide => { 
                dispatch(success(valide));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
   }

    function request(passwordData) { return { online: true, type: userConstants.RESET_PASSWORD_REQUEST, passwordData } }
    function success(valide) { return { type: userConstants.RESET_PASSWORD_SUCCESS, valide } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

const askResetPassword = (email)  =>{
    return dispatch => {
        dispatch(request(email));

        userConnexion.askResetPassword(email)
            .then(
                valide => { 
                    dispatch(success(valide));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(email) { return { online: true, type: userConstants.ASK_RESET_PASSWORD_REQUEST, email } }
    function success(valide) { return { type: userConstants.ASK_RESET_PASSWORD_SUCCESS, valide } }
    function failure(error) { return { type: userConstants.ASK_RESET_PASSWORD_FAILURE, error } }
}

const updateUserGeoloc = (geoloc)  =>{
    return dispatch => {
        dispatch(request( {geoloc }));

        userConnexion.updateUserGeoloc(geoloc)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(geoloc) { return { online: true, type: userConstants.USER_UPDATE_GEOLOC_REQUEST, geoloc } }
    function success(user) { return { type: userConstants.USER_UPDATE_GEOLOC_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_UPDATE_GEOLOC_FAILURE, error } }
}


const acceptFullPayps = (isFull)  =>{
    return dispatch => {
        dispatch(request( {isFull }));

        userConnexion.acceptFullPayps(isFull)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(isFull) { return { online: true, type: userConstants.USER_ACCEPT_FULL_PAYPS_REQUEST, isFull } }
    function success(user) { return { type: userConstants.USER_ACCEPT_FULL_PAYPS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_ACCEPT_FULL_PAYPS_FAILURE, error } }
}


const updateUnknownGeoloc = (geoloc) =>{
    return dispatch => {
        userConnexion.acceptUnknownGeoloc()
        .then(
            user => { 
                dispatch(success(user));
            },
        );
    }
    function success(user) { return {type:userConstants.USER_UNKNOWN_GEOLOC_ACCEPT, user} }
}

const updateUnknownCoord = (coordinates) =>{
    return dispatch => {
        userConnexion.acceptUnknownCoord(coordinates)
        .then(
            user => { 
                dispatch(success(user));
            },
        );
    }
    function success(user) { return {type:userConstants.USER_UNKNOWN_COORD_ACCEPT, user} }
}

/**
 * Accept Club CGU (for existing Payps user whi didn't accepted previously
 * @param {boolean} hasAccepted 
 * @returns 
 */
const acceptClubCgu = (hasAccepted)  =>{
    return function(dispatch) {
        dispatch(request(hasAccepted));
        
        return userConnexion.acceptClubCgu(hasAccepted)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
   }

    function request(hasAccepted) { return { online: true, type: userConstants.USER_ACCEPT_CLUB_CGU_REQUEST, hasAccepted } }
    function success(user) { return { type: userConstants.USER_ACCEPT_CLUB_CGU_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_ACCEPT_CLUB_CGU_FAILURE, error } }
}

const displayPasswordForm = () =>{
    return dispatch => {
        dispatch(request());
    }
    function request() { return {type:userConstants.DISPLAY_PASSWORD_FORM} }
}

const passwordUpdated = () =>{
    return dispatch => {
        dispatch(request());
    }
    function request() { return {type:userConstants.PASSWORD_UPDATED} }
}

const registrationNeeded = (errorMessage) =>{
    return dispatch => {
        dispatch(request(errorMessage));
    }
    function request(errorMessage) { return {type:userConstants.REGISTRATION_NEEDED, errorMessage} }
}

const logout = () =>{
    return dispatch => {
        dispatch(request( ));

        userConnexion.logout()
        .then(
            user => { 
                dispatch(success(user));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    }
    function request() { return { type: userConstants.USER_LOGOUT_REQUEST } }
    function success() { return { type: userConstants.USER_LOGOUT_SUCCESS } }
    function failure(error) { return { type: userConstants.USER_LOGOUT_FAILURE, error } }
}

export const userActions = {
    login,
    socialLogin,
    register,
    socialRegister,
    update,
    updatePassword,
    askResetPassword,
    resetPassword,
    displayPasswordForm,
    passwordUpdated,
    updateUserGeoloc,
    acceptFullPayps,
    acceptClubCgu,
    updateUnknownGeoloc,
    updateUnknownCoord,
    registrationNeeded,
    logout
};