import { userConstants } from 'redux/constants/userConstants';
import { loadStorage } from 'components/helpers/storageManager';
import {messageConstants} from 'redux/constants/messageConstants';

const user = loadStorage();
const initialState = user ? { loggedIn: true, isUpdated:false, ...user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    // LOGIN
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        errorMessage: "",
        formData: action.user.userData,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: {},
        disconnect: false,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state, 
        loading: false,
        errorMessage: action.error
      };

    // SOCIAL LOGIN
    case userConstants.SOCIAL_LOGIN_REQUEST:
      return {
        ...state,
        errorMessage: "",
        loading: true,
      };
    case userConstants.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        disconnect: false,
        user: action.user
      };
    case userConstants.SOCIAL_LOGIN_FAILURE:
      return {
        ...state, 
        loading: false,
        errorMessage: action.error,
        provider: action.provider,
        socialResponse: action.socialResponse,
        socialRedirect: action.socialRedirect
      };

    // CREATE USER
    case userConstants.REGISTER_REQUEST:
      return { 
        ...state,
        loading: true,
        errorMessage: "",
        formData: action.user.userData,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        disconnect: false,
        formData: {},
        user: action.user
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state, 
        loading: false,
        errorMessage: action.error
      };

      // CREATE USER BY SOCIAL NETWORK
    case userConstants.SOCIAL_REGISTER_REQUEST:
    return { 
      ...state,
      loading: true,
      errorMessage: "",
      formData: action.user.userData,
    };
  case userConstants.SOCIAL_REGISTER_SUCCESS:
    return {
      ...state,
      loading: false,
      disconnect: false,
      formData: {},
      user: action.user
    };
  case userConstants.SOCIAL_REGISTER_FAILURE:
    return {
      ...state, 
      loading: false,
      registerByNetwork: true,
      errorMessage: action.error
    };
    
      //UPDATE USER
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: "",
        formData: action.user.userData,
    };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        formData: {},
        user: action.user
    };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
    };

    //UPDATE PASSWORD USER
    case userConstants.DISPLAY_PASSWORD_FORM:
      return{
        ...state,
        isUpdated: false,
      };

    case userConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        formData: action.user.password,
        errorMessage: "",
        loading: true
    };
    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        formData: {},
        user: action.user
    };
    case userConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
    };

    case userConstants.PASSWORD_UPDATED:
      return{
        ...state,
        isUpdated: false,
      };

    /// ASK FOR EMAILRESET PASSWORD
    case userConstants.ASK_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        formData: action.email,
        errorMessage: "",
        loading: true
    };
    case userConstants.ASK_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
    };
    case userConstants.ASK_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
    };

    /// RESET PASSWORD
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        formData: action.passwordData,
        errorMessage: "",
        loading: true
    };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        formData: {}
    };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
    };

    /// UPDATE USER GEOLOC
    case userConstants.USER_UPDATE_GEOLOC_REQUEST:
      return {
        ...state,
        formData: action.geoloc,
        errorMessage: "",
        geolocAccepted: false,
        loading: true
    };
    case userConstants.USER_UPDATE_GEOLOC_SUCCESS:
      return {
        ...state,
        loading: false,
        geolocAccepted: true,
        formData: {},
        user: action.user
    };
    case userConstants.USER_UPDATE_GEOLOC_FAILURE:
      return {
        ...state,
        loading: false,
        geolocAccepted: false,
        errorMessage: action.error
    };
    case userConstants.USER_UNKNOWN_GEOLOC_ACCEPT:
      return {
        ...state,
        loading: false,
        geolocAccepted: true,
        formData: {},
        user: action.user
    };

    case userConstants.USER_UNKNOWN_COORD_ACCEPT:
      return {
        ...state,
        loading: false,
        formData: {},
        user: action.user
    };

    /// ACCEPT FULL PAYPS
    case userConstants.USER_ACCEPT_FULL_PAYPS_REQUEST:
      return {
        ...state,
        formData: action.isFull,
        fullPaypsAccepted: false,
        errorMessage: "",
        loading: true
    };

    case userConstants.USER_ACCEPT_FULL_PAYPS_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: {},
        fullPaypsAccepted: true,
        user: action.user
    };
    case userConstants.USER_ACCEPT_FULL_PAYPS_FAILURE:
      return {
        ...state,
        loading: false,
        fullPaypsAccepted: false,
        errorMessage: action.error
    };

    /// ACCEPT CLUB CGU
    case userConstants.USER_ACCEPT_CLUB_CGU_REQUEST:
      return {
        ...state,
        formData: action.isFull,
        errorMessage: "",
        loading: true
    };

    case userConstants.USER_ACCEPT_CLUB_CGU_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: {},
        user: action.user
    };
    case userConstants.USER_ACCEPT_CLUB_CGU_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
    };
    

    /// REDIRECT TO LOGIN
    case userConstants.REGISTRATION_NEEDED:
      return {
        ...state,
        redirectToLogin: true,
        errorMessage: action.errorMessage
      };

    /// LOGOUT
    case userConstants.USER_LOGOUT_REQUEST:
      return {
        disconnect: true,
      };
    
    case userConstants.USER_LOGOUT_SUCCESS:
      return {
        loading: false
    };

    case userConstants.USER_LOGOUT_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
    };

    /// REMOVE ERROR_MESSAGE
    case messageConstants.MESSAGE_HIDE:
    return {
        ...state,
        errorMessage: ""
    };

    default:
      return state
  }
}