import React from 'react';
import PropTypes from 'prop-types';

import homeScreenIcon from 'media/images/menu/homeScreenSafari.jpg';
const isIpad = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /ipad|ipod/.test( userAgent );
  }
const extraIpadMessage = isIpad() ? window['webAppContent'].POP_SAFARI.IPAD_TEXT : ""

const SafariPwaPopin = ({onClick}) => (
    <div className="safariPopin" onClick={()=>onClick()}>
        <div className="logoPayps"></div>
        <div className="textInstallation">{window['webAppContent'].POP_SAFARI.INSTALLATION_TEXT_A} {window['webAppConfig'].webAppEnv} {window['webAppContent'].POP_SAFARI.INSTALLATION_TEXT_B}  
            <img src={homeScreenIcon} alt={window['webAppContent'].POP_SAFARI.INSTALLATION_TEXT_C} className="homeScreenIcon"/>  &nbsp; {extraIpadMessage}
            {window['webAppContent'].POP_SAFARI.INSTALLATION_TEXT_C}
        </div>
        <span className="arrow"></span>
    </div>
  )

  
SafariPwaPopin.propTypes = {
    onClick: PropTypes.func
}

export default SafariPwaPopin;