import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import { couponsActions } from 'redux/actions/couponsActions';

import { Power2, TweenLite } from "gsap/all";

class CartIcon extends Component{
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }

    componentDidMount(){
        // Au chargement on récupère la liste des coupons dans le panier
        const { dispatch, user } = this.props;
        if(user && user.uuid){
            dispatch(couponsActions.getUserCart(user.uuid));
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.cart && (this.props.newCartValue > this.props.cart.length)){
            TweenLite.to(".cartIcon", {scaleX: 1.6, scaleY: 1.6, duration: .3, repeat: 1, yoyo: true, ease: Power2.easeOut });
        }
    }

    onCartClick = (event) =>{
        const {user} = this.props;
        if(user){
            this.props.history.push("/cart");
        }else{
            this.props.history.push("/registration-needed");
        }
    }

    render(){
        const {cart, newCartValue} = this.props;
        let cartNumber = cart ? cart.length : 0;
        if(cart && newCartValue > cart.length){
            cartNumber = newCartValue;
        }

        return(
            <div className="cartIcon" onClick={this.onCartClick}>
                <div className="cartValue">
                    {cartNumber}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { cart, couponAdded, newCartValue } = state.couponing;
    const { user } = state.authentication;
    return {
        user,
        cart,
        couponAdded,
        newCartValue
    };
}

export default connect(mapStateToProps)(withRouter(CartIcon)); 