import axios from 'axios';
import qs from 'qs';
import { saveStorage, loadStorage } from 'components/helpers/storageManager';

/// Config de Axios
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    // Qs is already included in the Axios package
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };
  return config;
});

class couponsConnexion {

    /*
    *Get list of available coupons
    @Parameters 
    **/
   static async getAllCoupons(filterData){
    let config = {
      params: filterData
    };
    if(loadStorage() && loadStorage().accessToken){
      config = {
          headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token},
          ...config
        };
      }
      try{
          const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/coupons', config);
          if(response.data.valide === 1){
            let totalCouponValue = 0;
            response.data.listCoupons.forEach(campaign => {
                totalCouponValue += Number.parseFloat(campaign.coupon.facial_value);
            });
            const paypsStorage = {listCoupons: response.data.listCoupons, totalCouponValue: totalCouponValue};
            if(!filterData.retailerId){
              paypsStorage.maxCouponValue = totalCouponValue;
            }
            saveStorage(paypsStorage);
            const dataReturn = {listCoupons: response.data.listCoupons, totalCouponValue: totalCouponValue, coordDetails: response.data.coordDetails,
                                retailerData: response.data.retailerData, appVersion:response.data.version};
            return Promise.resolve(dataReturn);
          }else{
            return Promise.reject(response.data.message);
          }
        }
        catch(error){
          return Promise.reject(error);
        }
    }

     /*
    *Get a coupon details
    @Parameters 
    couponId
    **/
   static async getCouponById(filterData){
    let config = {
      params: filterData
    };
    if(loadStorage() && loadStorage().accessToken){
      config = {
        headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token},
        ...config
      };
    }
      try{
          const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/coupon/'+filterData.campaignId, config);
          if(response.data.valide === 1){
            const dataReturn = {couponDetails: response.data.couponDetails};
            return Promise.resolve(dataReturn);
          }else{
            return Promise.reject(response.data.message);
          }
        }
        catch(error){
          return Promise.reject("Désolé, impossible de récupérer ce bon de réduction");
        }
    }

    /*Add coupon in user's cart
    @Parameters 
    campaignId string
    **/
   static async addCouponToCart(couponData){
      const config = {
        headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
      };
      try{
          const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/cart/addToCart', couponData, config);
          if(response.data.valide === 1){
            // If OK we update the listCoupon on storage and redux store
            let updatedListCoupon = loadStorage().listCoupons;
            let couponIndex = null;
            if(!Array.isArray(updatedListCoupon)){
              updatedListCoupon = [];
            }
            updatedListCoupon.forEach((item, index) => {
              if(item.uuid === couponData.campaignId){
                couponIndex = index;
              }
            });
            updatedListCoupon.splice(couponIndex, 1);
            const dataReturn = {cart: response.data.cart, listCoupons: updatedListCoupon};
            const paypsStorage = dataReturn;
            saveStorage(paypsStorage);
            return Promise.resolve(dataReturn);
          }else{
            return Promise.reject(response.data.message);
          }
        }
        catch(error){
          return Promise.reject("Désolé, impossible d'ajouter le bon de réduction");
        }
    }

    /*Get User's cart
    @Parameters 
    userId string
    @return list of coupon array
    **/
   static async getUserCart(userId){
    let config = {};
    if(loadStorage() && loadStorage().accessToken){
      config = {
        headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
      };
    }
    try{
        const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/cart/'+userId, config);
        if(response.data.valide === 1){
          const paypsStorage = {cart: response.data.cart};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data.cart);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de récupérer le panier");
      }
  }

  /*Wipe User's cart
   *Only available for selected admins 
    @return empty list of coupon array
    **/
   static async wipeUserCart(){
    const config = {
      headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token}
    };
    try{
        const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/cart/wipe', '', config);
        if(response.data.valide === 1){
          const paypsStorage = {cart: response.data.cart};
          saveStorage(paypsStorage);
          window.location.reload();
          return Promise.resolve(response.data.cart);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de vider le panier");
      }
  }

  /*Get list of geolocalized retailers
  @return empty list of retailers array
  **/
   static async getRetailers(coordinates){
    let config = {
      params: coordinates
    };
    if(loadStorage() && loadStorage().accessToken){
      config = {
        headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token},
        ...config
      };
    }
    try{
        const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/retailers', config);
        if(response.data.valide === 1){
          let retailersList = [];
          let paypsStorage = null;
          if(response.data.paging.currentPage === 1){
            retailersList = response.data.listRetailers;
            paypsStorage = {retailers: retailersList};
          }else{
            let prevList = loadStorage().retailers;
            retailersList = prevList.concat(response.data.listRetailers)
            paypsStorage = {retailers: retailersList};
          }
          saveStorage(paypsStorage);
          let responseData = response.data;
          responseData.listRetailers = retailersList;
          return Promise.resolve(responseData);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de récupérer la liste des magasins");
      }
  }

  /*Check an EAN for a campaign
    @Parameters 
    campaignId string
    ean string
    **/
   static async checkProductEan(couponData){
    const config = {
      params: {ean: couponData.ean}
    };
    try{
        const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/coupon/'+couponData.campaignId+'/isProductValid', config);
        if(response.data.valide === 1){
          const isProductValid = response.data.isProductValid;
          return Promise.resolve(isProductValid);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de vérifier ce produit");
      }
  }

  /**
   * Get RetailerBrand config
   * 
   * @param string slug
   */
  static async getRetailerBrandConfig(slug)
  {
    const config = {
      params: {slug}
    };
    try{
        const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/retailers/brands/getFromSlug', config);
        if(response.data.valide === 1){
          const retailerBrandConfig = response.data.brandConfig;
          return Promise.resolve(retailerBrandConfig);
        }else{
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        return Promise.reject("Désolé, impossible de récupérer les informations de cette enseigne");
      }
  }

  /**
   * Get a list of all the club cards
   * @param {string} campaignId 
   * @returns 
   */
    static async getAllStamps(campaignId){
      let config = {
        params: campaignId
      };
      if(loadStorage() && loadStorage().accessToken){
        config = {
            headers: {'Authorization': "Bearer " + loadStorage().accessToken.access_token},
            ...config
          };
        }
        try{
            const response = await axios.get(process.env.REACT_APP_API_PAYPS+'/fidelityCampaigns/all', config);
            if(response.data.valide === 1){
              const paypsStorage = {fidelityCampaignList: response.data.fidelityCampaignList};
              saveStorage(paypsStorage);
              const dataReturn = {fidelityCampaignList: response.data.fidelityCampaignList};
              return Promise.resolve(dataReturn);
            }else{
              return Promise.reject(response.data.message);
            }
          }
          catch(error){
            return Promise.reject(error);
          }
      }

  /**
   * 
   * @returns Clean Store after user logout
   */
  static cleanStore(){
      return Promise.resolve("Store cleaned");
  }
}

export default couponsConnexion;