import React from 'react';
import PropTypes from 'prop-types';

const DisplayError = ({onClick}) => (
    <div className= 'paypsScene errorScene'  onClick={() => onClick()} >
        <img src={process.env.REACT_APP_IMAGES_HOST+"errorImage.jpg"} className="errorImage" alt={window['webAppContent'].POP_ERROR.DESCRIPTION} />
        <div className="errorText">
            <div className="errorTitle">
                {window['webAppContent'].POP_ERROR.TITLE}
            </div>
            {window['webAppContent'].POP_ERROR.ERROR_TEXT}<br />
            {window['webAppContent'].POP_ERROR.ERROR_BTN}
        </div>
    </div>
)

DisplayError.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default DisplayError;