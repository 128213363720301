export const giftCardsConstants = {

    GET_GIFT_CARDS_LIST_REQUEST: 'GET_GIFT_CARDS_LIST_REQUEST',
    GET_GIFT_CARDS_LIST_SUCCESS: 'GET_GIFT_CARDS_LIST_SUCCESS',
    GET_GIFT_CARDS_LIST_FAILURE: 'GET_GIFT_CARDS_LIST_FAILURE',

    ACTIVATE_GIFT_CARD_REQUEST: 'ACTIVATE_GIFT_CARD_REQUEST',
    ACTIVATE_GIFT_CARD_SUCCESS: 'ACTIVATE_GIFT_CARD_SUCCESS',
    ACTIVATE_GIFT_CARD_FAILURE: 'ACTIVATE_GIFT_CARD_FAILURE',

    GET_GIFT_CARD_REQUEST: 'GET_GIFT_CARD_REQUEST',
    GET_GIFT_CARD_SUCCESS: 'GET_GIFT_CARD_SUCCESS',
    GET_GIFT_CARD_FAILURE: 'GET_GIFT_CARD_FAILURE',

    DEFINE_MAX_CHECKOUT_REQUEST: 'DEFINE_MAX_CHECKOUT_REQUEST',
    DEFINE_MAX_CHECKOUT_SUCCESS: 'DEFINE_MAX_CHECKOUT_SUCCESS',
    DEFINE_MAX_CHECKOUT_FAILURE: 'DEFINE_MAX_CHECKOUT_FAILURE',

    GET_GIFT_CARD_CAMPAIGN_REQUEST: 'GET_GIFT_CARD_CAMPAIGN_REQUEST',
    GET_GIFT_CARD_CAMPAIGN_SUCCESS: 'GET_GIFT_CARD_CAMPAIGN_SUCCESS',
    GET_GIFT_CARD_CAMPAIGN_FAILURE: 'GET_GIFT_CARD_CAMPAIGN_FAILURE',

    CHECK_PRODUCT_EAN_REQUEST: 'CHECK_PRODUCT_EAN_REQUEST',
    CHECK_PRODUCT_EAN_SUCCESS: 'CHECK_PRODUCT_EAN_SUCCESS',
    CHECK_PRODUCT_EAN_FAILURE: 'CHECK_PRODUCT_EAN_FAILURE',
    CHECK_PRODUCT_EAN_CLEAN: 'CHECK_PRODUCT_EAN_CLEAN',
};