import React, { Component } from 'react';
//import 'css/app.css';
import './sass/app.scss';

import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import Router from 'components/Router';
import LoadingItem from 'components/graphics/LoadingItem';
import Header from 'components/modules/header/Header';


class App extends Component {
  constructor(props) {
		super(props);
		this.state = {
			promptRefresh: false,
		};
  }
  

  componentDidUpdate(prevProps){
    // If location change we scroll to top of page
    if(prevProps.location.pathname !== this.props.location.pathname){
      window.scrollTo(0, 0);
    }
  }
  
  render() {

    const {loading} = this.props;
    let showRealHeader = true;
    let showReturnButton = false;
    if(this.props.location.pathname === "/login" || this.props.location.pathname ==="/" || this.props.location.pathname ==="/create-user"){
      showRealHeader = false;
    }
    if(this.props.location.pathname.includes("coupon-details") || this.props.location.pathname ==="/cart" || this.props.location.pathname ==="/showQrCode"
    || this.props.location.pathname.includes("giftCard") || this.props.location.pathname.includes("activer-carte-cadeau")){
      showReturnButton = true;
    }
    const regexp = /^\/mes-points\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\//gm;
    if(regexp.test(this.props.location.pathname) ){
      showReturnButton = true;
    }

    return (
      <div className={`${window['webAppConfig'].globalContainer}`}>
        <Header 
          showRealHeader={showRealHeader}
          showReturnButton={showReturnButton}
        />
        {loading && 
          <LoadingItem />
        }
          <Router />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state.authentication;
  return {
      loading,
      user
  };
}

export default withRouter(connect(mapStateToProps)(App));
