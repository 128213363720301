export const couponsConstants = {

    GET_LIST_COUPONS_REQUEST: 'GET_LIST_COUPONS_REQUEST',
    GET_LIST_COUPONS_SUCCESS: 'GET_LIST_COUPONS_SUCCESS',
    GET_LIST_COUPONS_FAILURE: 'GET_LIST_COUPONS_FAILURE',

    GET_COUPONS_DETAILS_REQUEST: 'GET_COUPONS_DETAILS_REQUEST',
    GET_COUPONS_DETAILS_SUCCESS: 'GET_COUPONS_DETAILS_SUCCESS',
    GET_COUPONS_DETAILS_FAILURE: 'GET_COUPONS_DETAILS_FAILURE',

    GET_USER_CART_REQUEST: 'GET_USER_CART_REQUEST',
    GET_USER_CART_SUCCESS: 'GET_USER_CART_SUCCESS',
    GET_USER_CART_FAILURE: 'GET_USER_CART_FAILURE',

    SHOW_COUPON_DETAILS: 'SHOW_COUPON_DETAILS',

    ADD_COUPON_TO_CART_REQUEST: 'ADD_COUPON_TO_CART_REQUEST',
    ADD_COUPON_TO_CART_SUCCESS: 'ADD_COUPON_TO_CART_SUCCESS',
    ADD_COUPON_TO_CART_FAILURE: 'ADD_COUPON_TO_CART_FAILURE',

    WIPE_USER_CART_REQUEST: 'WIPE_USER_CART_REQUEST',
    WIPE_USER_CART_SUCCESS: 'WIPE_USER_CART_SUCCESS',
    WIPE_USER_CART_FAILURE: 'WIPE_USER_CART_FAILURE',

    GET_RETAILERS_REQUEST: 'GET_RETAILERS_REQUEST',
    GET_RETAILERS_SUCCESS: 'GET_RETAILERS_SUCCESS',
    GET_RETAILERS_FAILURE: 'GET_RETAILERS_FAILURE',

    CHECK_PRODUCT_EAN_REQUEST: 'CHECK_PRODUCT_EAN_REQUEST',
    CHECK_PRODUCT_EAN_SUCCESS: 'CHECK_PRODUCT_EAN_SUCCESS',
    CHECK_PRODUCT_EAN_FAILURE: 'CHECK_PRODUCT_EAN_FAILURE',
    CHECK_PRODUCT_EAN_CLEAN: 'CHECK_PRODUCT_EAN_CLEAN',

    LAUNCH_CART_ANIMATION: 'LAUNCH_CART_ANIMATION',

    CLEAN_STORE_REQUEST: 'CLEAN_STORE_REQUEST',
    CLEAN_STORE_SUCCESS: 'CLEAN_STORE_SUCCESS',
    CLEAN_STORE_FAILURE: 'CLEAN_STORE_FAILURE',

    GET_RETAILER_BRAND_CONFIG_REQUEST: 'GET_RETAILER_BRAND_CONFIG_REQUEST',
    GET_RETAILER_BRAND_CONFIG_SUCCESS: 'GET_RETAILER_BRAND_CONFIG_SUCCESS',
    GET_RETAILER_BRAND_CONFIG_FAILURE: 'GET_RETAILER_BRAND_CONFIG_FAILURE',

    GET_STAMPS_LIST_REQUEST: 'GET_STAMPS_LIST_REQUEST',
    GET_STAMPS_LIST_SUCCESS: 'GET_STAMPS_LIST_SUCCESS',
    GET_STAMPS_LIST_FAILURE: 'GET_STAMPS_LIST_FAILURE',
};