import React from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const LegalsFooter = ({position, onLinkClick}) => (
    <div className={(position ==="menu") ? "legalsMenu" : "legalsFooter"}>
        <span><Link to='/mentions-legales' onClick={onLinkClick ? onLinkClick : null}>{window['webAppContent'].FOOTER.LEGALS}</Link></span>
        <span><Link to='/cgu' onClick={onLinkClick ? onLinkClick : null}>{window['webAppContent'].FOOTER.CGU}</Link></span>
        <span><Link to='/charte-utilisateur' onClick={onLinkClick ? onLinkClick : null}>{window['webAppContent'].FOOTER.USER}</Link></span>
        <span><Link to='/politique-confidentialite' onClick={onLinkClick ? onLinkClick : null}>{window['webAppContent'].FOOTER.CONFIDENTIALITY}</Link></span>
        <span><Link to='/politique-cookies'  onClick={onLinkClick ? onLinkClick : null}>{window['webAppContent'].FOOTER.COOKIES}</Link></span>
    </div>
  )

LegalsFooter.propTypes = {
  position: PropTypes.string,
  onLinkClick: PropTypes.func
};

export default LegalsFooter;