import {messageConstants} from 'redux/constants/messageConstants';


const showMessage = (messageData) => {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: messageConstants.MESSAGE_SHOW, messageData } }
}

const hideMessage = () => {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: messageConstants.MESSAGE_HIDE } }
}


export const messageActions = {
    showMessage,
    hideMessage
}