import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import qs from 'qs';
import history from "components/helpers/history.js";

import {PrivateRoute} from 'components/helpers/PrivateRoute';
import withErrorHandler from 'components/helpers/withErrorHandler';
import LoadingItem from 'components/graphics/LoadingItem';
import {saveStorage} from'components/helpers/storageManager';

import { ThemeProvider} from '@mui/material/styles';
import PaypsMuiTheme from 'components/graphics/PaypsMuiTheme';

///SCENES
const LoadableHome = lazy(() => import('components/scenes/Home'));
const LoadableContact = lazy(() => import('components/scenes/Contact'));
const LoadableListCoupons = lazy(() => import('components/scenes/ListCoupons'));
const LoadableDedicatedListCoupons = lazy(() => import('components/scenes/DedicatedListCoupons'));
const LoadableLogin = lazy(() => import('components/scenes/Login'));
const LoadableCreateUser = lazy(() => import('components/scenes/CreateUser'));
const LoadableUpdateUser = lazy(() => import('components/scenes/UpdateUser'));
const LoadableDefault404 = lazy(() => import('components/scenes/Default404'));
const LoadablePasswordUpdate = lazy(() => import('components/scenes/PasswordUpdate'));
const LoadableCouponDetails = lazy(() => import('components/scenes/CouponDetails'));
const LoadableCart = lazy(() => import('components/scenes/Cart'));
const LoadableShowQrCode = lazy(() => import('components/scenes/ShowQrCode'));
const LoadableDidacticiel = lazy(() => import('components/scenes/Didacticiel'));
const LoadableRegistrationNeeded = lazy(() => import('components/scenes/RegistrationNeeded'));
const LoadablePasswordReset = lazy(() => import('components/scenes/PasswordReset'));
const LoadableAskNewPassword = lazy(() => import('components/scenes/AskNewPassword'));
const LoadableRetailers = lazy(() => import('components/scenes/Retailers'));
const LoadableExternalCouponDetails = lazy(() => import('components/scenes/ExternalCouponDetails'));
const LoadableCheckProduct = lazy(() => import('components/scenes/CheckProduct'));
const LoadableCheckGiftCardProduct = lazy(() => import('components/scenes/CheckGiftCardProduct'));
const LoadableStampList = lazy(() => import('components/scenes/StampList'));
const LoadableStampListDetails = lazy(() => import('components/scenes/StampListDetails'));
const LoadableAcceptCgu = lazy(() => import('components/scenes/AcceptCgu'));
//const LoadableActivateGiftCard = lazy(() => import('components/scenes/ActivateGiftCard'));
const LoadableListGiftCard = lazy(() => import('components/scenes/ListGiftCard'));
const LoadableGiftCardDetails = lazy(() => import('components/scenes/GiftCardDetails'));
const LoadableGiftCardCampaignDetails = lazy(() => import('components/scenes/GiftCardCampaignDetails'));


const LoadablePolitiqueCookies = lazy(() => import('components/scenes/rgpd/PolitiqueCookies'));
const LoadableMentionsLegales = lazy(() => import('components/scenes/rgpd/MentionsLegales'));
const LoadableCgu = lazy(() => import('components/scenes/rgpd/Cgu'));
const LoadableCharteUtilisateur = lazy(() => import('components/scenes/rgpd/CharteUtilisateur'));
const LoadablePolitiqueConfidentialite = lazy(() => import('components/scenes/rgpd/PolitiqueConfidentialite'));



class Router extends Component {

    componentDidMount(){
        const searchParams = history.location.search;
        if(searchParams !== ""){
            const urlParams = {queryParams : history.location.search.slice(1)};
            const {campaignId, sourceId} = qs.parse(urlParams.queryParams);
            if(campaignId && sourceId){
                saveStorage(urlParams);
            }
        }
    }

    renderLoading = () => (<LoadingItem />);
    render() {
        return (
            <div className="sceneContainer">
                 <ThemeProvider theme={PaypsMuiTheme}>
                    <Suspense fallback={this.renderLoading()}>
                        <Switch>
                            <Route exact path="/" component={withErrorHandler(LoadableHome)} />
                            <Route exact path="/registration-needed" component={withErrorHandler(LoadableRegistrationNeeded)} />
                            <Route exact path="/how-it-works" component={withErrorHandler(LoadableDidacticiel)} />
                            <Route exact path="/list-coupons" component={withErrorHandler(LoadableListCoupons)} />
                            <Route exact path="/list-coupons/:retailerId" component={withErrorHandler(LoadableListCoupons)} />
                            <Route exact path="/enseignes/coupons/:retailerBrandSlug" component={withErrorHandler(LoadableDedicatedListCoupons)} />
                            <Route path='/login' component={withErrorHandler(LoadableLogin)}/>
                            <Route path='/create-user' component={withErrorHandler(LoadableCreateUser)} />
                            <Route exact path='/coupon-details/:campaignId' component={withErrorHandler(LoadableCouponDetails)}/>
                            <Route exact path='/coupon-details/:campaignId/checkProduct' component={withErrorHandler(LoadableCheckProduct)}/>
                            <Route exact path='/ask-new-password' component={withErrorHandler(LoadableAskNewPassword)}/>
                            <Route path="/reset-password/:token/:email/:fresh?" component={withErrorHandler(LoadablePasswordReset)} />
                            <PrivateRoute exact path='/cart' component={withErrorHandler(LoadableCart)} />
                            <PrivateRoute exact path='/showQrCode' component={withErrorHandler(LoadableShowQrCode)} />
                            <PrivateRoute exact path='/update-user' component={withErrorHandler(LoadableUpdateUser)} />
                            <PrivateRoute exact path='/update-password' component={withErrorHandler(LoadablePasswordUpdate)} />
                            <PrivateRoute exact path='/accept-cgu' component={withErrorHandler(LoadableAcceptCgu)} />
                            <PrivateRoute exact path='/mes-points' component={withErrorHandler(LoadableStampList)} />
                            <PrivateRoute exact path='/mes-points/:fidelityCampaignId/:retailerId/:cardId/details' component={withErrorHandler(LoadableStampListDetails)} />
                            <Route exact path="/retailers/:campaignId?" component={withErrorHandler(LoadableRetailers)} />
                            <Route exact path='/liste-carte-cadeau' component={withErrorHandler(LoadableListGiftCard)} />
                            <Route exact path='/carte-cadeau/:giftCardCampaignId/details' component={withErrorHandler(LoadableGiftCardCampaignDetails)} />
                            <Route exact path='/carte-cadeau/:giftCardCampaignId/checkProduct' component={withErrorHandler(LoadableCheckGiftCardProduct)}/>
                            <PrivateRoute exact path='/giftCard/:giftCardId/details' component={withErrorHandler(LoadableGiftCardDetails)} />
                            <Route exact path='/contact' component={withErrorHandler(LoadableContact)} />
                            <Route exact path="/coupon/:campaignId" component={withErrorHandler(LoadableExternalCouponDetails)} />
                            <Route exact path="/politique-cookies" component={withErrorHandler(LoadablePolitiqueCookies)} />
                            <Route exact path="/mentions-legales" component={withErrorHandler(LoadableMentionsLegales)} />
                            <Route exact path="/cgu" component={withErrorHandler(LoadableCgu)} />
                            <Route exact path="/charte-utilisateur" component={withErrorHandler(LoadableCharteUtilisateur)} />
                            <Route exact path="/politique-confidentialite" component={withErrorHandler(LoadablePolitiqueConfidentialite)} />
                            <Route component={withErrorHandler(LoadableDefault404)} />
                        </Switch>
                    </Suspense>
                 </ThemeProvider>
            </div>
        )
    }
}

export default Router;