import { giftCardsConstants } from 'redux/constants/giftCardsConstants';
import { loadStorage } from 'components/helpers/storageManager';

const storage = loadStorage();
const giftCardsList = storage && storage.giftCardsList ? storage.giftCardsList : [];
const initialState = {
    giftCardsList: giftCardsList,
    giftCardDetails: {},
    giftCardCampaignDetails: {}

};


export function giftCards(state = initialState, action) {
    switch (action.type) {
        /// Get gift cards list list
        case giftCardsConstants.GET_GIFT_CARDS_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
            };
        
        case giftCardsConstants.GET_GIFT_CARDS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                giftCardsList: action.giftCardsList.giftCardsList
            };

        case giftCardsConstants.GET_GIFT_CARDS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        /// Activate a giftCard
        case giftCardsConstants.ACTIVATE_GIFT_CARD_REQUEST:
        case giftCardsConstants.GET_GIFT_CARD_REQUEST:
        case giftCardsConstants.DEFINE_MAX_CHECKOUT_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
            };
        
        case giftCardsConstants.ACTIVATE_GIFT_CARD_SUCCESS:
        case giftCardsConstants.GET_GIFT_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                giftCardDetails: action.giftCardDetails.giftCardDetails
            };

        case giftCardsConstants.ACTIVATE_GIFT_CARD_FAILURE:
        case giftCardsConstants.GET_GIFT_CARD_FAILURE:
        case giftCardsConstants.DEFINE_MAX_CHECKOUT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        case giftCardsConstants.DEFINE_MAX_CHECKOUT_SUCCESS:
            // We keep the previous object (since it contains all the purchases) and update only the max_checkout value
            let updatedDetails = state.giftCardDetails;
            updatedDetails.max_checkout = action.giftCardDetails.giftCardDetails.max_checkout;
            return {
                ...state,
                loading: false,
                errorMessage: "",
                giftCardDetails: updatedDetails
            };

         /// Get a giftCardCampaignDetails
         case giftCardsConstants.GET_GIFT_CARD_CAMPAIGN_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: "",
            };
        
        case giftCardsConstants.GET_GIFT_CARD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                giftCardCampaignDetails: {
                    giftCardCampaign: action.giftCardCampaignDetails.giftCardCampaign,
                    retailersList: action.giftCardCampaignDetails.retailersList,
                },
                coordDetails: action.giftCardCampaignDetails.coordDetails,
                paging: action.giftCardCampaignDetails.paging
            };

        case giftCardsConstants.GET_GIFT_CARD_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        
         /// CHECK PRODUCT EAN
         case giftCardsConstants.CHECK_PRODUCT_EAN_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: "",
            };
        
        case giftCardsConstants.CHECK_PRODUCT_EAN_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                isProductValid: action.isProductValid
            };

        case giftCardsConstants.CHECK_PRODUCT_EAN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case giftCardsConstants.CHECK_PRODUCT_EAN_CLEAN:
            return {
                ...state,
                isProductValid: undefined,
                errorMessage: "",
            };
        
        default:
            return state
    }
}