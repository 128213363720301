import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import userConnexion from 'components/connectors/userConnexion';


export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        userConnexion.isLoggedIn()
            ?  <Component {...props} />
            : <Redirect to={{ pathname: '/registration-needed', state: { from: props.location.pathname } }} />
    )} />
)
