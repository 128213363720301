import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import { rootReducer } from 'redux/reducers/rootReducer';
import requestMiddleware from 'redux/middlewares/requestMiddleware';
import checkOnlineMiddleware from 'redux/middlewares/checkOnlineMiddleware';
import checkErrorMiddleware from 'redux/middlewares/checkErrorMiddleware';

//const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        checkOnlineMiddleware,
        requestMiddleware,
        checkErrorMiddleware,
        thunkMiddleware,
        //loggerMiddleware
    )
);
