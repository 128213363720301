import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

import { Router } from 'react-router-dom';
import history from "components/helpers/history.js";
import { Provider } from "react-redux";
import {store} from "redux/store";
import ErrorBoundary from 'components/helpers/ErrorBoundary';

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Ubuntu:300,400,500,600,700:latin&display=swap', 'Source+Sans+Pro:400,600,700,900:latin&display=swap']
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>
  , document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();