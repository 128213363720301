import { couponsConstants } from 'redux/constants/couponsConstants';
import {userConstants } from 'redux/constants/userConstants';
import  couponsConnexion  from 'components/connectors/couponsConnexion';
import  userConnexion  from 'components/connectors/userConnexion';


const getAllCoupons = (filterData) => {
    return dispatch => {
        dispatch(request({ filterData }));

        couponsConnexion.getAllCoupons(filterData)
            .then(
                couponData => { 
                    dispatch(success(couponData));
                },
                error => {
                    if(error.response && error.response.status === 403){
                        userConnexion.logout();
                        dispatch(disconnect());
                    }else{
                        dispatch(failure("Désolé, impossible de récupérer les bons de réduction"));
                    }
                }
            );
    };

    function request(listCoupons) { return { online: true, type: couponsConstants.GET_LIST_COUPONS_REQUEST, listCoupons } };
    function success(couponData) { return { type: couponsConstants.GET_LIST_COUPONS_SUCCESS, couponData } };
    function failure(error) { return { type: couponsConstants.GET_LIST_COUPONS_FAILURE, error } };
    function disconnect(){return {type: userConstants.USER_LOGOUT_REQUEST}};
}

const getUserCart = (userId) => {
    return dispatch => {
        dispatch(request({ userId }));

        couponsConnexion.getUserCart(userId)
            .then(
                cart => { 
                    dispatch(success(cart));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(userId) { return { online: true, type: couponsConstants.GET_USER_CART_REQUEST, userId } }
    function success(cart) { return { type: couponsConstants.GET_USER_CART_SUCCESS, cart } }
    function failure(error) { return { type: couponsConstants.GET_USER_CART_FAILURE, error } }
}

const getCouponDetails = (campaignId) => {
    return dispatch => {
        dispatch(request({campaignId}));

        couponsConnexion.getCouponById(campaignId)
            .then(
                couponDetails => { 
                    dispatch(success(couponDetails));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
        );
    };

    function request(campaignId) { return { online: true, type: couponsConstants.GET_COUPONS_DETAILS_REQUEST, campaignId } }
    function success(couponDetails) { return { type: couponsConstants.GET_COUPONS_DETAILS_SUCCESS, couponDetails } }
    function failure(error) { return { type: couponsConstants.GET_COUPONS_DETAILS_FAILURE, error } }
}

const addCouponToCart = (couponData) => {
    return dispatch => {
        dispatch(request({ couponData }));

        couponsConnexion.addCouponToCart(couponData)
            .then(
                cart => { 
                    dispatch(success(cart));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(couponData) { return { online: true, type: couponsConstants.ADD_COUPON_TO_CART_REQUEST, couponData } }
    function success(cart) { return { type: couponsConstants.ADD_COUPON_TO_CART_SUCCESS, cart, couponData} }
    function failure(error) { return { type: couponsConstants.ADD_COUPON_TO_CART_FAILURE, error } }
}

const wipeUserCart = () => {
    return dispatch => {
        dispatch(request({  }));

        couponsConnexion.wipeUserCart()
            .then(
                cart => { 
                    dispatch(success(cart));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { online: true, type: couponsConstants.WIPE_USER_CART_REQUEST } }
    function success(cart) { return { type: couponsConstants.WIPE_USER_CART_SUCCESS, cart } }
    function failure(error) { return { type: couponsConstants.WIPE_USER_CART_FAILURE, error } }
}

const getRetailers = (coordinates) => {
    return dispatch => {
        dispatch(request({  }));

        couponsConnexion.getRetailers(coordinates)
            .then(
                response => { 
                    dispatch(success(response.listRetailers, response.totalApp, response.coordDetails, response.paging));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(coordinates) { return { online: true, type: couponsConstants.GET_RETAILERS_REQUEST, coordinates } }
    function success(retailers, totalApp, coordDetails, paging) { return { type: couponsConstants.GET_RETAILERS_SUCCESS, retailers, totalApp , coordDetails, paging} }
    function failure(error) { return { type: couponsConstants.GET_RETAILERS_FAILURE, error } }
}

const launchCartAnimation = (cartValue, discountValue) => {
    return { type: couponsConstants.LAUNCH_CART_ANIMATION, cartValue, discountValue }
}

const checkProductEan = (couponData) => {
    return dispatch => {
        dispatch(request({ couponData }));

        couponsConnexion.checkProductEan(couponData)
            .then(
                isProductValid => { 
                    dispatch(success(isProductValid));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(couponData) { return { online: true, type: couponsConstants.CHECK_PRODUCT_EAN_REQUEST, couponData } }
    function success(isProductValid) { return { type: couponsConstants.CHECK_PRODUCT_EAN_SUCCESS, isProductValid } }
    function failure(error) { return { type: couponsConstants.CHECK_PRODUCT_EAN_FAILURE, error } }
}

const getRetailerBrandConfig = (retailerSlug) => {
    return dispatch => {
        dispatch(request({ retailerSlug }));
        
        couponsConnexion.getRetailerBrandConfig(retailerSlug)
            .then(
                retailerBrandConfig => { 
                    dispatch(success(retailerBrandConfig));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(retailerSlug) { return { online: true, type: couponsConstants.GET_RETAILER_BRAND_CONFIG_REQUEST, retailerSlug } }
    function success(retailerBrandConfig) { return { type: couponsConstants.GET_RETAILER_BRAND_CONFIG_SUCCESS, retailerBrandConfig } }
    function failure(error) { return { type: couponsConstants.GET_RETAILER_BRAND_CONFIG_FAILURE, error } }
}

const cleanProductIsValid = () => {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: couponsConstants.CHECK_PRODUCT_EAN_CLEAN } }
}

const cleanStore = () =>{
    return dispatch => {
        dispatch(request( ));

        couponsConnexion.cleanStore()
        .then(
            user => { 
                dispatch(success(user));
            }
        );
    }
    function request() { return { type: couponsConstants.CLEAN_STORE_REQUEST } }
    function success() { return { type: couponsConstants.CLEAN_STORE_SUCCESS } }
}

/**
 * Get all the fidelity card (club by Payps) for a user
 */
const getAllStamps = (campaignId) => {
    return dispatch => {
        dispatch(request(campaignId));

        couponsConnexion.getAllStamps(campaignId)
            .then(
                fidelityCampaignList => { 
                    dispatch(success(fidelityCampaignList));
                },
                error => {
                    if(error.response && error.response.status === 403){
                        userConnexion.logout();
                        dispatch(disconnect());
                    }else{
                        dispatch(failure("Désolé, impossible de récupérer les cartes club By Payps"));
                    }
                }
            );
    };

    function request(campaignId) { return { online: true, type: couponsConstants.GET_STAMPS_LIST_REQUEST, campaignId } };
    function success(fidelityCampaignList) { return { type: couponsConstants.GET_STAMPS_LIST_SUCCESS, fidelityCampaignList } };
    function failure(error) { return { type: couponsConstants.GET_STAMPS_LIST_FAILURE, error } };
    function disconnect(){return {type: userConstants.USER_LOGOUT_REQUEST}};
}

export const couponsActions = {
    getAllCoupons,
    getCouponDetails,
    getUserCart,
    addCouponToCart,
    wipeUserCart,
    getRetailers,
    launchCartAnimation,
    checkProductEan,
    getRetailerBrandConfig,
    cleanProductIsValid,
    cleanStore,
    getAllStamps
};