import React, { Component } from 'react';
import  Menu  from "react-burger-menu/lib/menus/slide";
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from 'redux/actions/userActions';
import { couponsActions } from 'redux/actions/couponsActions';

import SafariPwaPopin from 'components/graphics/SafariPwaPopin';
import SocialNetwork from 'components/graphics/SocialNetwork';
import LegalsFooter from 'components/modules/footer/LegalsFooter';

class SidebarMenu extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      showSafariPopin: false
    };
    this.deferredPrompt = null;
  }

  componentDidMount(){
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      this.deferredPrompt = event;
      //console.log("on a assigné le deferedPrompt ", this.deferredPrompt);
    });
  }

  addToHomeScreen = (event) => {
    // We first close the menu
    this.setState({isMenuOpen: false});
    if(this.isSafari()){
      this.setState({showSafariPopin: true});
    }else{
      // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
    }
  }

  isIos(){
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  isSafari(){
    //const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    const isSafari = navigator.vendor.match(/apple/i) &&
                      !navigator.userAgent.match(/crios/i) &&
                      !navigator.userAgent.match(/fxios/i);
    //console.log("on checke ici le navigateur ", navigator.userAgent.indexOf('Safari'), navigator.userAgent.indexOf('Chrome'));
    return isSafari;
  }

  wipeCart = () =>{
    const { dispatch } = this.props;
    dispatch(couponsActions.wipeUserCart());
    this.closeMenu();
  }

  closeMenu = () => {
    this.setState({isMenuOpen: false});
  }

  onMenuStateChange = (newState) =>{
    this.setState({isMenuOpen: newState.isOpen});
  }

  onPopinClick = (event) =>{
    this.setState({showSafariPopin: false});
  }

  logout = () =>{
    this.setState({isMenuOpen: false});
    const { dispatch } = this.props;
    dispatch(userActions.logout());
    dispatch(couponsActions.cleanStore());
    this.props.history.push("/");
  }


  render(){
    const {user} = this.props;
    const isLoggedIn = (user && user.uuid) ? true : false;
    let showInstallHomeScreen = (this.isSafari() || this.deferredPrompt) ? true : false;
    if(this.isIos() === true){
      if(this.isSafari() === true){
        showInstallHomeScreen = true;
      }else{
        showInstallHomeScreen = false;
      }
    }

    return (
      <div>
        <Menu 
          isOpen={this.state.isMenuOpen}
          onStateChange={this.onMenuStateChange}
          customBurgerIcon={ <img src={process.env.REACT_APP_IMAGES_HOST+window['webAppConfig'].images.burgerMenu}  alt="Ouvrir le menu latéral" />}
          >
              {
                isLoggedIn &&
                <div className="menuUserItem">
                  <div className="blocNom">{user.firstName} {user.lastName}</div>
                  <div className="blocEmail">{user.email}</div>
                </div>
              }
            
            <div className="listMenu">
              {
                !isLoggedIn &&
                <NavLink className="menuCreateAccount" to='/' onClick={this.closeMenu}>{window['webAppContent'].MENU.LOGIN_BTN}</NavLink>
              }
              <NavLink className="menuListBr" to='/list-coupons' onClick={this.closeMenu}>{window['webAppContent'].MENU.COUPONS_BTN}</NavLink>
              <NavLink className="menuListRetailers" to='/retailers' onClick={this.closeMenu}>{window['webAppContent'].MENU.RETAILERS_BTN}</NavLink>
              {
                isLoggedIn && 
                  <NavLink className="menuUserAccount" to='/update-user' onClick={this.closeMenu}>{window['webAppContent'].MENU.ACCOUNT_BTN}</NavLink>
              }
              {
                isLoggedIn && 
                  <NavLink className="menuQrCode" to='/showQrCode' onClick={this.closeMenu}>{window['webAppContent'].MENU.PAYPS_ID_BTN}</NavLink>
              }
              {
                (isLoggedIn && (process.env.REACT_APP_ENV === "demo"  || process.env.REACT_APP_ENV === "demo-pharma"  || process.env.REACT_APP_ENV === "local") 
                )&& 
                <span className="menuUserDashboard" onClick={this.wipeCart}>{window['webAppContent'].MENU.WIPE_BTN}</span>
              }
              
              {
                (process.env.REACT_APP_ENV !== 'teampharma' && process.env.REACT_APP_ENV !== 'production') &&
                <NavLink className="menuCarteClub" to='/mes-points' onClick={this.closeMenu}>{window['webAppContent'].MENU.FIDELITY_CARD_BTN}</NavLink>
              }
              
              {
                (process.env.REACT_APP_ENV !== 'teampharma' && process.env.REACT_APP_ENV !== 'production') &&
                <NavLink className="menuListGiftCard" to='/liste-carte-cadeau' onClick={this.closeMenu}>{window['webAppContent'].MENU.GIFT_CARD_BTN}</NavLink>
              }

              <NavLink className="menuHowItWorks" to='/how-it-works' onClick={this.closeMenu}>{window['webAppContent'].MENU.FAQ_BTN}</NavLink>

              {
                showInstallHomeScreen &&
                <span className="menuHomeScreen" onClick={this.addToHomeScreen}>{window['webAppContent'].MENU.HOMEPAGE_BTN}</span>
              }
              {
                isLoggedIn && 
                <span className="menuDisconnect" onClick={this.logout}>{window['webAppContent'].MENU.DISCONNECT_BTN}</span>
              }
            </div>
            <LegalsFooter 
              position="menu"
              onLinkClick={this.closeMenu}
            />
            <div className="mobileRs">
                <SocialNetwork />
            </div>
            
        </Menu>
        {
          this.state.showSafariPopin &&
          <SafariPwaPopin onClick={this.onPopinClick}/>
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  const {user} = state.authentication;
  const {appVersion} = state.couponing;
  return {
      user,
      appVersion
  };
}

export default withRouter(connect(mapStateToProps)(SidebarMenu));