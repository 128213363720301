import { couponsConstants } from 'redux/constants/couponsConstants';
import { userConstants } from 'redux/constants/userConstants';
import { giftCardsConstants } from 'redux/constants/giftCardsConstants';
import { loadStorage } from 'components/helpers/storageManager';
import {messageConstants} from 'redux/constants/messageConstants';

const storage = loadStorage();
const fidelityCampaignList = storage && storage.fidelityCampaignList ? storage.fidelityCampaignList : [];
const initialState = (storage !== undefined && storage.listCoupons !== undefined) ? 
{ listCoupons: storage.listCoupons, totalCouponValue: storage.totalCouponValue, cart: storage.cart, fidelityCampaignList: fidelityCampaignList } 
: {listCoupons:[], totalCouponValue: 0, cart: [], fidelityCampaignList: []};


export function couponing(state = initialState, action) {
    switch (action.type) {
      // GET ALL FILTERED COUPONS
        case couponsConstants.GET_LIST_COUPONS_REQUEST:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                retailerData: null,
            };
        
        case couponsConstants.GET_LIST_COUPONS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                listCoupons: action.couponData.listCoupons,
                coordDetails: action.couponData.coordDetails,
                totalCouponValue: action.couponData.totalCouponValue,
                retailerData: action.couponData.retailerData,
                couponDetails: undefined,
                appVersion: action.couponData.appVersion
            };

        case couponsConstants.GET_LIST_COUPONS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        /// GET USER'S CART
        case couponsConstants.GET_USER_CART_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
            };
        
        case couponsConstants.GET_USER_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                cart: action.cart
            };

        case couponsConstants.GET_USER_CART_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        /// SHOW COUPON DETAILS
        case couponsConstants.SHOW_COUPON_DETAILS:
            return {
                ...state,
                loading: false,
                couponAdded: false,
                couponId: null,
                campaignId: action.campaignId
            }
        
        /// GET COUPON DETAILS
        case couponsConstants.GET_COUPONS_DETAILS_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                couponAdded: false,
                couponId: null,
                campaignId: action.campaignId
            }
        
        case couponsConstants.GET_COUPONS_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                couponDetails: action.couponDetails.couponDetails
            };

        case couponsConstants.GET_COUPONS_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        /// ADD COUPON TO CART
        case couponsConstants.ADD_COUPON_TO_CART_REQUEST:
            return {
                ...state,
                //loading: true,
                errorMessage: ""
            };
        
        case couponsConstants.ADD_COUPON_TO_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                couponAdded: true,
                couponId: action.couponData.campaignId,
                errorMessage: "",
                cart: action.cart.cart,
                listCoupons: action.cart.listCoupons,
            };

        case couponsConstants.ADD_COUPON_TO_CART_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        /// GET RETAILERS LIST
        case couponsConstants.GET_RETAILERS_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: ""
            };
        
        case couponsConstants.GET_RETAILERS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                retailers: action.retailers,
                coordDetails: action.coordDetails,
                paging: action.paging
                //totalCouponValue: action.totalApp
            };

        case couponsConstants.GET_RETAILERS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        
        /// WIPE USER'S CART
        case couponsConstants.WIPE_USER_CART_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
            };
        
        case couponsConstants.WIPE_USER_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                cart: action.cart,
                newCartValue: 0,
                newDiscountValue: "0,00"
            };

        case couponsConstants.WIPE_USER_CART_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        /// CART ANIMATION
        case couponsConstants.LAUNCH_CART_ANIMATION:
            return {
                ...state,
                newCartValue: action.cartValue,
                newDiscountValue: action.discountValue
            };

        /// USER LOGOUT
        /// Specific since we need to wipe the cart
        case userConstants.USER_LOGOUT_REQUEST:
            return {
                ...state,
                cart: [],
                loading:false,
                errorMessage: ""
            }
        
        /// REMOVE ERROR_MESSAGE
        case messageConstants.MESSAGE_HIDE:
            return {
                ...state,
                errorMessage: "",
                couponAdded: false
            };
        
        /// CHECK PRODUCT EAN
        case couponsConstants.CHECK_PRODUCT_EAN_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: "",
            };
        
        case couponsConstants.CHECK_PRODUCT_EAN_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                isProductValid: action.isProductValid
            };

        case couponsConstants.CHECK_PRODUCT_EAN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case couponsConstants.CHECK_PRODUCT_EAN_CLEAN:
            return {
                ...state,
                isProductValid: undefined,
                errorMessage: "",
            };
        
        /// GET RETAILER BRAND CONFIG
        case couponsConstants.GET_RETAILER_BRAND_CONFIG_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
            };
        
        case couponsConstants.GET_RETAILER_BRAND_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                retailerBrandConfig: action.retailerBrandConfig
            };

        case couponsConstants.GET_RETAILER_BRAND_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
                retailerBrandConfig: null
            };

        /// GET Club By Payps list
        case couponsConstants.GET_STAMPS_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                errorMessage: "",
            };
        
        case couponsConstants.GET_STAMPS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: "",
                fidelityCampaignList: action.fidelityCampaignList.fidelityCampaignList
            };

        case couponsConstants.GET_STAMPS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        
            // Clean stores on user Logout
        case couponsConstants.CLEAN_STORE_REQUEST:
        case couponsConstants.CLEAN_STORE_SUCCESS:
            return {
                listCoupons:[], 
                totalCouponValue: 0, 
                cart: []
            };

        ///// Just update the coordDetails object when launching a geoloc action on giftCardCampaign
        case giftCardsConstants.GET_GIFT_CARD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                coordDetails: action.giftCardCampaignDetails.coordDetails,
                
            };
        
        default:
            return state
    }
}